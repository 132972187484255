import * as React from "react";
import * as Sentry from "@sentry/nextjs";
import { AppProps } from "next/app";
import Head from "next/head";
import { ThemeProvider } from "next-themes";
import {
  Hydrate,
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
  setLogger,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { ThemeToggle } from "@/components/ThemeToggle";
import { Footer } from "@/components/Footer";
import "@/assets/style/style.scss";

const queryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: true,
      optimisticResults: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      staleTime: 60 * 1000,
    },
  },
};

setLogger({
  log: (message) => {
    Sentry.captureMessage(message);
  },
  warn: (message) => {
    Sentry.captureMessage(message);
  },
  error: (error) => {
    Sentry.captureException(error);
  },
});

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const [queryClient] = React.useState(
    () => new QueryClient(queryClientOptions)
  );
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ThemeProvider attribute="class" enableSystem defaultTheme="light">
          <Head>
            <meta charSet="UTF-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <title>Aurei</title>
            <meta
              name="description"
              content="Aurei - Community AUR-driven Arch Package Repository"
            />
            <link
              rel="preconnect"
              href={process.env.BASE_URL}
              crossOrigin="true"
            />
          </Head>

          <Component {...pageProps} />
          <ThemeToggle />
          <Footer />
        </ThemeProvider>
      </Hydrate>
      {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};

export default App;
