import * as React from "react";
import { useTheme } from "next-themes";

import SunIcon from "@/assets/images/icons/sun.svg";
import SunGlassesIcon from "@/assets/images/icons/sunglasses.svg";

const ThemeToggle: React.FC = () => {
  const { theme, setTheme } = useTheme() as {
    theme: string;
    setTheme: (theme?: string) => void;
  };
  const [mounted, setMounted] = React.useState(false);

  // When mounted on client, now we can show the UI
  React.useEffect(() => setMounted(true), []);

  const toggleTheme = () =>
    theme === "dark" ? setTheme("light") : setTheme("dark");

  if (!mounted) return null;

  return (
    <div className="fixed bottom-0 right-0 flex items-center p-2 m-1 mr-2 bg-white border border-gray-200 shadow-lg dark:bg-black-pearl dark:border-black-pearl-400">
      <button
        onClick={toggleTheme}
        className="block outline-none focus:outline-none"
        title={
          theme === "dark" ? "Turn on the lights!" : "Turn off the lights!"
        }
      >
        {theme === "dark" ? (
          <SunIcon className="w-6 h-6 svg-inline--fa fa-sun fa-w-16" />
        ) : (
          <SunGlassesIcon className="w-6 h-6 svg-inline--fa fa-sun fa-w-16" />
        )}
      </button>
    </div>
  );
};

export { ThemeToggle };
