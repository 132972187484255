import * as React from "react";
import CodeIcon from "@/assets/images/icons/code.svg";

const Footer: React.FC = React.memo(function Footer() {
  return (
    <footer className="py-4 mt-4 container text-center">
      <ul className="list-none space-x-2">
        <li className="inline-block">
          <a
            href={process.env.GITHUB_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </li>
        <li className="inline-block">
          <a
            href={process.env.PUBKEY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            GPG Key
          </a>
        </li>
      </ul>

      <p>
        <CodeIcon className="w-5 h-5 text-primary fill-current inline-block hover:animate-spin" />
        {` by `}
        <a
          href="https://github.com/aktowns"
          rel="noopener noreferrer"
          target="_blank"
        >
          @aktowns
        </a>
        {` and `}
        <a
          href="https://github.com/rmcfadzean"
          rel="noopener noreferrer"
          target="_blank"
        >
          @rmcfadzean
        </a>
      </p>
    </footer>
  );
});

export { Footer };
